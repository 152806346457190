<template>
  <Term />
</template>

<script>
import Term from '@/components/basic/term/Term.vue'

export default {
  components: {
    Term,
  },
  data() {
    return {
    }
  },
}
</script>

<style scoped>
</style>
