<template>
  <div class="">
    <div class="inside_">
      <div class="myform">
        <div class="title-spaced ">
          <div class="subtitle space-adjustment">
            利用規約
          </div>
          <div class="">
            株式会社コレクト・エージェンシー(以下、コレクト・エージェンシー)が提供する職業紹介サービス(以下、当該サービス)のご利用にあたっては、以下の利用規約をご確認、ご承諾いただいたうえでお申込みくださいますようお願い致します。
          </div>
          <div class="subtitle">
            第1条(職業紹介サービス)
          </div>
          <div class="">
            職業紹介サービスとは、コレクト・エージェンシーが運営するサイト「転職コレクション」、及びそれを包括した人材紹介・職業紹介サービスの総称をいいます。
          </div>
          <div class="subtitle">
            第2条(会員)
          </div>
          <div class="">
            1.当該サービスにおける会員とは、1条におけるサービスを希望し、利用申込を行ない、弊社がこれを承諾した利用者のことをいいます。
          </div>
          <div class="">
            2.会員は利用申込の時点で、本規約の内容を全て承諾しているものとみなします。
          </div>

          <div class="subtitle">
            第3条(会員の責任)
          </div>
          <div class="">
            1.会員は、自らの意思によってのみ当該サービスに登録し、会員となるものとします。
          </div>
          <div class="">
            2.会員はサービスの利用において不備・齟齬がないように自らの情報を登録するものとします。登録情報の不備・齟齬により発生した事項については、コレクト・エージェンシーは一切責任を負いません。
          </div>
          <div class="subtitle">
            第4条(登録の取り消し)
          </div>
          <div class="">
            会員が以下のいずれかに該当した場合は、コレクト・エージェンシーの判断にて登録の取り消しを行ない、当該サービスの提供を終了する場合があります。
          </div>
          <div class="">
            A)本規約の定めに違反した場合
          </div>
          <div class="">
            B)虚偽の情報を提供または登録した場合、または意図的に情報を隠蔽した場合。
          </div>
          <div class="">
            C)正当な理由なく、コレクト・エージェンシーの連絡にご返信いただけない場合
          </div>
          <div class="">
            D)当該サービスを通じて紹介を受けた企業に対し、コレクト・エージェンシーの承諾なく直接連絡をとり、採用選考を受ける、または入社する行為があった場合。
          </div>
          <div class="">
            E)正当な理由なく、面談もしくは選考試験・面接を欠席し、または採用内定を受諾後に辞退する等の行為があった場合
          </div>
          <div class="">
            F)コレクト・エージェンシー、及び紹介先企業、または第三者に不利益を与える行為、誹謗・中傷・苦情・差別発言等の不適切な行為、または名誉、信用を損なう行為があった場合
          </div>
          <div class="">
            G)犯罪に結びつく行為、及び法令または公序良俗に反する行為があった場合
          </div>
          <div class="">
            H)調査、及び営利を目的とする当該サービスへの登録、情報提供行為があった場合
          </div>
          <div class="">
            I)当該サービス、及び関連企業の運営を妨げる行為があった場合
          </div>
          <div class="">
            J)当該サービスの範囲を超える要望、要求を行なった場合
          </div>
          <div class="">
            K)その他、客観的、合理的事由によりコレクト・エージェンシーが不適切と判断する行為があった場合
          </div>

          <div class="subtitle">
            第5条(職業紹介サービス)
          </div>
          <div class="">
            1.当該サービスにて会員に提供する、一般には公開されていない求人案件及びその関連情報については、会員は第三者へ提供・開示等を行なえないものとします。
          </div>
          <div class="">
            2.当該サービスのうち、弊社コンサルタントが応募受付、面接日時の調整及び選考結果の連絡等を行なっている案件については、求人企業への連絡は原則全て弊社コンサルタントが行ない、会員本人から求人企業への直接の連絡は行なうことはできません。
          </div>

          <div class="subtitle">
            第6条(サービス進行の照会)
          </div>
          <div class="">
            当該サービスにおいて、検討基準や判断理由等をお伝えすることは基本できません。また、会員が紹介を要望した場合でも、選考基準などを参考にして判断した結果、当該企業への推薦を行なわない場合や、求人企業に代わり求人条件に適合しない旨のご連絡を行なう場合があります。
          </div>
          <div class="subtitle">
            第7条(求人企業への情報提供)
          </div>
          <div class="">
            コレクト・エージェンシーは事前に本人の同意を得たうえで、会員の過去の賞罰等についての情報を求人企業に提供する場合があります。なお、同意を得られない場合は、当該サービスを提供できなくなる場合があります。
          </div>
          <div class="subtitle">
            第8条(転職先企業への事実確認)
          </div>
          <div class="">
            コレクト・エージェンシーは、当該サービスを利用して転職を行った会員の労働条件の事実、または入退社の状況等を、求人企業に確認する場合があります。
          </div>
          <div class="subtitle">
            第9条(個人情報の取り扱い)
          </div>
          <div class="">
            コレクト・エージェンシーは別途定める「プライバシーポリシー」に従い、会員の個人情報を適切に取得・利用・提供・管理します。
          </div>

          <div class="subtitle">
            第10条(免責)
          </div>
          <div class="">
            コレクト・エージェンシーは故意・重過失がない限り、以下の事項について免責されます。
          </div>
          <div class="">
            1.当該サービスは、会員の就職・転職を保証するものではありません。
          </div>
          <div class="">
            2.当該サービスにおいて提供される求人情報は第三者の責任で提供されるものであり、当該情報の真実性、正確性、合法性、安全性、適切性及び有用性についてはコレクト・エージェンシーが保証をする対象ではありません。
          </div>
          <div class="">
            3.会員と第三者との間での紛争が生じた場合には、会員の責任と負担で当該第三者と協議・解決を図ること
          </div>
          <div class="">
            4.会員は、紹介先企業との雇用契約を結ぶ際には自己の責任に基づいて行うこと
          </div>
          <div class="">
            5.天災地変やネットワーク障害、通常講ずるべきウィルス対策では防止できないウィルス被害及びその他の不可抗力により発生した損害
          </div>
          <div class="">
            6.会員が当該サービスを利用した上で、コレクト・エージェンシーの責めに帰すべき事由によって損害が発生した場合、コレクト・エージェンシーが責任を負うのは直接かつ現実的に発生した損害の範囲に限定されること
          </div>
          <div class="subtitle">
            第11(登録の抹消)
          </div>
          <div class="">
            1.会員は自らの意思によりいつでも当該サービスを退会することができます。
          </div>
          <div class="">
            2.退会の意思がなくとも、⻑期に渡り、当該サービスへの連絡・アクセスが無かった場合、もしくはコレクト・エージェンシーからの連絡に返信せず放置状態となった場合、担当者の合理的な判断により抹消される事があります。
          </div>

          <div class="subtitle">
            第12条(損害賠償)
          </div>
          <div class="">
            会員は、本規約の定めに違反してコレクト・エージェンシーまたは第三者に直接かつ現実的に損害を与えた場合は、その損害を賠償するものとします。
          </div>

          <div class="subtitle">
            第13条(サービス及び規約の変更等)
          </div>
          <div class="">
            1.コレクト・エージェンシーは会員への事前の通知なくして、当該サービスの内容変更または一時的な中断を行なうことがあります。
          </div>
          <div class="">
            2.コレクト・エージェンシーは一定の予告期間をもって会員に通知のうえ、当該サービスの提供を⻑期的に中断もしくは終了することができます。但し、やむを得ない事情がある場合は、予告を行なわない場合があります。
          </div>
          <div class="">
            3.コレクト・エージェンシー、会員の事前の承認を得ることなく本規約を随時変更することができます。本規約の変更については、当該サービスに関するサイト上に公示した時点から効力を発するものとします。なお、会員が本規約の変更後に、当愛サービスを利用することにより、本規約の変更に承諾いただいたものとみなします。
          </div>
          <div class="subtitle">
            第14条(反社会的勢力の排除)
          </div>
          <div class="">
            会員は、当該サービスを利用するにあたり、次に定める事項を表明し、保証するものとします。また、かかる表明・保証に違反した場合、コレクト・エージェンシーは催告を要することなく直ちに、当該サービスの提供を打ち切り、締結している契約及び申込を解約できるものとします。
          </div>
          <div class="">
            1.会員が反社会的勢力(「暴力団員による不当な行為の防止等に関する法律」に定義する暴力団及びその関係団体その他の反社会的団体または勢力をいうものとします)ではないこと
          </div>
          <div class="">
            2.反社会的勢力を利用しないこと及びこれに準ずる行為をしないこと
          </div>
          <div class="">
            3.反社会的勢力に資金提供を行なわないこと及びこれに準ずる行為をしないこと
          </div>
          <div class="">
            4.反社会的勢力を名乗る等してコレクト・エージェンシー及び、第三者の名誉等の毀損、業務妨害、不当要求行為、またはこれに準ずる行為をしないこと
          </div>
          <div class="subtitle">
            第15条(管轄裁判所)
          </div>
          <div class="">
            本規約に関する準拠法は日本法とし、本規約に関して生じる一切の紛争については、東京簡易裁判所または東京地方裁判所を第一審の合意管轄裁判所とします。
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  components: {
  },
  directives: {
  },
  data() {
    return {
    }
  },
  methods: {
    cancel() {
      //
    },
  },
}
</script>

<style scoped>
.whole {
    /* background-color: rgba(217, 229, 255, 0.3); */
    color:#555555;
    margin: 5% 0;
  }
.inside {
  /* margin: 2%; */
  padding: 0 0 0% 0;
  /* background-color: white; */
  height: 100%;
}
@media screen and (min-width:896px){
    .inside {
      margin: 1%;
    }
  }
.myform{
  /* background-color: white; */
  width: 70%;
  margin: 3% auto;
  line-height: 2.8rem;
}
@media screen and (max-width:480px){
/*画面幅が480pxまでの時*/
  .myform{
    width: 90%;
    font-size: 0.6rem;
    line-height: 1.4rem;
  }
}
/* @media screen and (min-width:781px){ */
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .myform{
    width: 50%;
  }
}
@media screen and (max-width:480px){
/*画面幅が480pxまでの時*/
  .subtitle {
    font-weight: 600;
    margin: 15% 0 3% 0;
    font-size: 1rem;
  }
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .subtitle {
    font-weight: 600;
    margin: 3% 0;
    font-size: 1.4rem;
  }
}
.space-adjustment {
  margin: 25% 0 0% 0;
}
@media screen and (min-width:896px){
  /*画面幅が781px以上の時*/
  .space-adjustment {
    margin: 10% 0 0% 0;
  }
}
</style>
